<!-- dropdown -->
<div class="dropdown-list" [style]="position" *ngIf="lightboxDropdown" (click)="lightboxOnClick($event)">
  <div class="search" *ngIf="dataOption.length > 10">
    <input type="text" placeholder="搜索选项" [(ngModel)]="searchedDropdown" (keyup)="searchDrop($event)" />
    <div class="close" (click)="clearSearch()" *ngIf="searchedDropdown"><i class="iconfont">close</i></div>
  </div>
  <div class="list">
    <div class="box" [style]="{'display' : option.name.toLowerCase().includes(searchedDropdown.toLowerCase()) ? 'block' : 'none'}" *ngFor="let option of dataOption; index as optionindex" (click)="selectDropdownOption(option)">{{ option.name }}</div>
  </div>
</div>

<!-- selector -->
<div class="selector-list" [style]="position" *ngIf="lightboxSelector" (click)="lightboxOnClick($event)">
  <div class="left">
    <div class="search">
      <input type="text" placeholder="搜索选项" [(ngModel)]="searchedSelector" (keyup)="searchSelector($event)" />
      <div (click)="clearSearch()" *ngIf="searchedSelector"><i class="iconfont">close</i></div>
    </div>
    <div class="list">
      <div class="box" [style]="{'display' : searchedSelector === '' || option.name.includes(searchedSelector) ? 'block' : 'none'}" *ngFor="let option of listOption; index as optionindex" (click)="selectSelectorOption(option)">{{ option.name }}</div>
    </div>
  </div>
  <div class="right">
    <div class="action">
      <div>{{ selectedOption.length }} 被选</div>
      <span *ngIf="selectedOption && selectedOption.length > 0" (click)="clearAllOption()">删除全部</span>
    </div>
    <div class="list">
      <div class="box" *ngFor="let item of selectedOption; index as itemindex" (click)="removeSelectedOption(item)">
        {{ item.name }}
        <span><i class="iconfont">close</i></span>
      </div>
    </div>
  </div>
  <div class="button">
    <div (click)="confirmSelect()">确认选项</div>
  </div>
</div>

<div class="input-box" [class]="dataObj.type + ' ' + dataObj.class" [style]="dataObj.style">
  <div class="title" *ngIf="dataObj.title && dataObj.input !== 'upload' && dataObj.input !== 'checkbox'">
    {{ dataObj.title }}
  </div>
  <div class="inputcover" [class]="{'error' : error, 'disabled' : disabled, 'readonly' : dataObj.readonly, 'clonable' : dataObj.input === 'reader'}">

    <!-- input -->
    <input *ngIf="dataObj.input === 'input' || dataObj.input === 'reader'" 
           [type]="dataObj.type === 'password' ? visibility ? 'text' : 'password' : dataObj.type" 
           [readonly]="dataObj.input == 'reader'"
           autocomplete="new-password"
           (input)="inputOnChange($event)"
           (keypress)="keyPress($event)"
           [(ngModel)]="inputValue" />

    <!-- textarea -->
    <textarea *ngIf="dataObj.input === 'textarea'" 
              [(ngModel)]="inputValue"
              (change)="inputOnChange($event)">
    </textarea>

    <!-- editor -->
    <div class="editor" *ngIf="dataObj.input === 'editor'">
      <ngx-editor-menu [toolbar]="toolbar" [editor]="editor"> </ngx-editor-menu>
      <ngx-editor [editor]="editor" [(ngModel)]="inputValue" (ngModelChange)="inputOnEditor($event)"></ngx-editor>
    </div>

    <!-- dropdown -->
    <div class="dropdown" 
         *ngIf="dataObj.input === 'dropdown'" 
         (click)="toggleDropdown($event)">
         {{ dropDownValue }}
    </div>

    <!-- selector -->
    <div class="selector" 
         *ngIf="dataObj.input === 'select'" 
         (click)="toggleSelector($event)">
         {{ selectorValue }}
    </div>

    <!-- checkbox -->
    <div class="checkbox" *ngIf="dataObj.input === 'checkbox'" (click)="inputOnCheck()">
      <div class="box"><span *ngIf="inputValue"></span></div>
      <div class="text">{{ dataObj.title }}</div>
    </div>

    <!-- switch -->
    <div class="switch" *ngIf="dataObj.input === 'switch'" (click)="inputOnSwitch()">
      <div [class]="{'active' : inputValue}">是</div>
      <div [class]="{'active' : !inputValue}">否</div>
      <span [class]="{'active' : inputValue}"></span>
    </div>

    <!-- uploader -->
    <label class="uploader" for="upload" *ngIf="dataObj.input === 'upload'">
      <span>{{ dataObj.title || '上传' }}</span>
      <input type="file" id="upload" name="upload" (change)="inputOnUpload($event)">
    </label>

    <!-- password visibility -->
    <div class="view" *ngIf="dataObj.type === 'password'" (click)="toggleVisibility()">
      <i class="iconfont">{{ visibility ? 'visibility' : 'visibility_off' }}</i>
    </div>

    <!-- clone value -->
    <div class="clone" *ngIf="dataObj.input === 'reader'" (click)="copyText()">
      <i class="iconfont">content_copy</i>
    </div>
  </div>
  <div class="note" *ngIf="dataObj.note">
    * {{ dataObj.note }}
  </div>
</div>