import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// import { ApiService } from '../../service/api.service';
// import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss']
})
export class ProfilePage implements OnInit {

  'formData' : any = {
    'id' : {
      'input' : 'input',
      'value' : 'AA0001',
      'title' : 'Employee ID',
      'disabled': true,
      'style' : 'width:200px; float:left;'
    },
    'position' : {
      'input' : 'input',
      'value' : 'Personal Assistant',
      'title' : 'Employee Position',
      'disabled': true,
      'style' : 'width:calc(100% - 220px); float:right;',
      'note'  : 'Contact admin to change your employee position'
    },
    'firstname' : {
      'input' : 'input',
      'value' : 'Daneil',
      'title' : 'First Name',
      'disabled': true,
      'style' : 'width:calc(50% - 10px); float:left;',
      'note'  : 'Contact admin to change your first name'
    },
    'lastname' : {
      'input' : 'input',
      'value' : 'Lim',
      'title' : 'Last Name',
      'disabled': true,
      'style' : 'width:calc(50% - 10px); float:right;',
      'note'  : 'Contact admin to change your last name'
    },
    'displayname' : {
      'input' : 'input',
      'value' : '',
      'title' : 'Display Name',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:left;',
      'note'  : ''
    },
    'phone' : {
      'input' : 'input',
      'value' : '0122345566',
      'title' : 'Phone Number',
      'disabled': true,
      'style' : 'width:calc(50% - 10px); float:left;',
      'note'  : 'Contact admin to change your phone number'
    },
    'email' : {
      'input' : 'input',
      'value' : 'testing@hotmail.com',
      'title' : 'Email Address',
      'disabled': true,
      'style' : 'width:calc(50% - 10px); float:right;',
      'note'  : 'Contact admin to change your email address'
    },
    'dob' : {
      'input' : 'input',
      'type'  : 'date',
      'value' : '',
      'title' : 'Date of Birth',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:left; margin:0px 20px 0px 0px;',
      'note'  : ''
    },
    'gender' : {
      'input' : 'switch',
      'value' : 1,
      'style' : 'width:180px; float:left;',
      'option': [
        {
          'id' : 1,
          'name' : 'Male'
        },
        {
          'id' : 2,
          'name' : 'Female'
        }
      ],
      'title' : 'Gender'
    },
    'address1' : {
      'input' : 'input',
      'value' : '',
      'title' : 'Address 1',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:left;',
      'note'  : ''
    },
    'address2' : {
      'input' : 'input',
      'value' : '',
      'title' : 'Address 2',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:right;',
      'note'  : ''
    },
    'postcode' : {
      'input' : 'input',
      'value' : '',
      'title' : 'Postcode',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:left;',
      'note'  : ''
    },
    'statecountry' : {
      'input' : 'input',
      'value' : '',
      'title' : 'State & Country',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:right;',
      'note'  : ''
    },
    'bio' : {
      'input' : 'textarea',
      'value' : '',
      'title' : 'Your Bio'
    },
    'update' : {
      'class': 'submit',
      'icon' : '',
      'text' : 'Update Changes',
      'style': ''
    }
  };
  
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    // public global: GlobalStore,
    // public translate: TranslateService,
  ) { }

  ngOnInit(): void {

  }
  

}

