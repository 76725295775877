<table *ngIf="col && data && data.length > 0">
  <thead>
    <tr>
      <th [class]="ele.class" [style]="ele.style" *ngFor="let ele of col; index as cindex;">{{ ele.text }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of data; index as rindex;">
      <td [class]="ele.class" *ngFor="let ele of col; index as cindex;">
          <div [title]="row[ele.class]" [style]="ele.style" *ngIf="ele.type === 'text'">{{ row[ele.class] }}</div>

          <div class="imgcover" *ngIf="ele.type === 'image'">
            <div class="image" [style]="{'background-image' : 'url(' + row[ele.class] + ')'}">
              <img [src]="'../../../assets/img/template/' + ele.data.template + '.png'" />
            </div>
          </div>

          <div class="actioncover" *ngIf="ele.type === 'action' && ele.button">
            <button-box [title]="btn.data.title" [data]="btn.data" [disabled]="btn.disabled(row)" *ngFor="let btn of ele.button; index as bindex;" (click)="onClick(btn, row)"></button-box>
          </div>
      </td>
    </tr>
  </tbody>
</table>

<div class="pagination" *ngIf="pagArr && pagArr.length > 0">
  <div class="pagcover">
    <div class="leftpag">
      <div class="pbox" (click)="selectPage(1)"><i class="iconfont">keyboard_double_arrow_left</i></div>
      <div class="pbox" (click)="prevPage()"><i class="iconfont">chevron_left</i></div>
    </div>
  
    <swiper [config]="swiperConfig">
      <ng-template swiperSlide class="auto" *ngFor="let num of pagArr; index as index;">
        <div class="pagbox" [class]="{'active' : selectedPag === index + 1}" (click)="selectPage(index + 1)">{{ index + 1 }}</div>
      </ng-template>
    </swiper>
  
    <div class="rightpag">
      <div class="pbox" (click)="nextPage()"><i class="iconfont">chevron_right</i></div>
      <div class="pbox" (click)="selectPage(pagArr.length)"><i class="iconfont">keyboard_double_arrow_right</i></div>
    </div>
  </div>
</div>