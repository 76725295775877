import { Component } from '@angular/core';
import { GlobalStore } from './service/global.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ApiService } from './service/api.service';

import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public global : GlobalStore,
    private route: ActivatedRoute,
    public router: Router,
    private ApiService: ApiService
  ) {
    this.router.events.subscribe((event:any) => 
    {   
      if(event instanceof NavigationEnd)
      {
        // check on login status
        if(event.url.includes('?'))
        {
          let url = event.url.split('?')[0];
          if(url !== '/login')
          {
            this.checkStatus();
          }
        }
        else
        {
          if(event.url !== '/login')
          {
            this.checkStatus();
          }
        }
      } 
    });
  }
  
  'menuData' : any = [
    {
      'id'  : 1,
      'cat' : '用户管理',
      'code': 'menu.user',
      'data': [
        {
          'title' : '客户端用户列表',
          'code'  : 'menu.memberlist',
          'route' : '/memberlist',
          'icon'  : 'face'
        },
        {
          'title' : '后端用户列表',
          'code'  : 'menu.userlist',
          'route' : '/userlist',
          'icon'  : 'engineering'
        },
        {
          'title' : '用户权限',
          'code'  : 'menu.rolelist',
          'route' : '/rolelist',
          'icon'  : 'assignment'
        }
      ]
    },
    {
      'id'  : 2,
      'cat' : '直播管理',
      'code': 'menu.user',
      'data': [
        {
          'title' : '直播列表',
          'code'  : 'menu.streamlist',
          'route' : '/streamlist',
          'icon'  : 'videocam'
        }
      ]
    },
    {
      'id'  : 3,
      'cat' : '视频管理',
      'code': 'menu.video',
      'data': [
        {
          'title' : '视频列表',
          'code'  : 'menu.videolist',
          'route' : '/videolist',
          'icon'  : 'desktop_windows'
        },
        {
          'title' : '短视频列表',
          'code'  : 'menu.shortlist',
          'route' : '/shortlist',
          'icon'  : 'smartphone'
        },
        {
          'title' : '大师教学列表',
          'code'  : 'menu.education',
          'route' : '/educationlist',
          'icon'  : 'school'
        }
      ]
    },
    {
      'id'  : 4,
      'cat' : '内容管理',
      'code': 'menu.content',
      'data': [
        {
          'title' : '标签',
          'code'  : 'menu.taglist',
          'route' : '/taglist',
          'icon'  : 'local_offer'
        },
        {
          'title' : '横幅',
          'code'  : 'menu.banner',
          'route' : '/bannerlist',
          'icon'  : 'tab'
        },
        {
          'title' : '热门活动',
          'code'  : 'menu.activities',
          'route' : '/activitieslist',
          'icon'  : 'local_activity'
        },
        {
          'title' : '白名单',
          'code'  : 'menu.whitelist',
          'route' : '/whitelist',
          'icon'  : 'language'
        }
      ]
    }
  ];
  'swiperConfig' : SwiperOptions = {
    'slidesPerView': 2,
    'spaceBetween': 20,
    'navigation': false,
    'loop' : true,
    'autoplay' : {
      'delay' : 5000
    }
  };

  ngOnInit(): void {

  }

  checkStatus()
  {
    this.global.getUserInfo((res:any) =>
    {
      
    }, (error:any) =>
    {
      localStorage.removeItem('token');
      localStorage.removeItem('userinfo');
      window.location.href = '/login';
    });
  }

  navigate(route:string)
  {
    window.location.href = route;
  }
  closeDialog()
  {
    this.global.dialog = null;
  }
}
