import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SwiperOptions } from 'swiper';
// import { ApiService } from '../../service/api.service';
// import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './configuration.page.html',
  styleUrls: ['./configuration.page.scss']
})
export class ConfigurationPage implements OnInit {

  'swiperConfig' : SwiperOptions = {
    'slidesPerView': 'auto',
    'spaceBetween': 10,
    'navigation': false
  };
  'tabData' : any = [
    {
      'text' : 'Site Setting',
      'code' : ''
    },
    {
      'text' : 'User Information',
      'code' : ''
    }
  ];
  'selectedTabIndex': number = 0;
  'formData' : any = {
    'logo' : {
      'class': '',
      'icon' : 'file_upload',
      'text' : 'Upload Logo',
      'style': 'background:#ffffff; color:#444878'
    },
    'maintainance' : {
      'input' : 'switch',
      'value' : 1,
      'style' : 'width:160px; float:left; margin:0px 20px 20px 0px;',
      'option': [
        {
          'id' : 1,
          'name' : 'Off'
        },
        {
          'id' : 2,
          'name' : 'On'
        }
      ],
      'title' : 'Site Maintainance'
    },
    'theme' : {
      'input' : 'switch',
      'value' : 1,
      'style' : 'width:160px; float:left; margin:0px 20px 20px 0px;',
      'option': [
        {
          'id' : 1,
          'name' : 'light'
        },
        {
          'id' : 2,
          'name' : 'dark'
        }
      ],
      'title' : 'Color & Theme'
    },
    'language' : {
      'input' : 'select',
      'value' : 1,
      'style' : 'width:340px',
      'option': [
        {
          'id' : 1,
          'value': '1',
          'name' : 'English'
        },
        {
          'id' : 2,
          'value': '2',
          'name' : 'Simplified Chinese'
        }
      ],
      'title' : 'Site Language'
    },
    'starttime' : {
      'input' : 'input',
      'value' : '',
      'title' : 'Operating Time (Start Time)',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:left;',
      'note'  : 'write in 24 hour format'
    },
    'endtime' : {
      'input' : 'input',
      'value' : '',
      'title' : 'Operating Time (End Time)',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:right;',
      'note'  : 'write in 24 hour format'
    },
    'address' : {
      'input' : 'input',
      'value' : '',
      'title' : 'Address',
      'disabled': false,
      'style' : '',
      'note'  : ''
    },
    'mobile' : {
      'input' : 'input',
      'value' : '',
      'title' : 'Phone Number',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:left;',
      'note'  : ''
    },
    'email' : {
      'input' : 'input',
      'value' : '',
      'title' : 'Email',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:right;',
      'note'  : ''
    },
    'desc' : {
      'input' : 'input',
      'value' : 'Thank you, Come again.',
      'title' : 'Description',
      'disabled': false,
      'style' : 'margin: 0px 0px 100px 0px;',
      'note'  : 'will be show at the bottom part of the receipt'
    },
    'password' : {
      'input' : 'input',
      'value' : '',
      'type'  : 'password',
      'title' : 'Password',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:left',
      'note'  : ''
    },
    'newpassword' : {
      'input' : 'input',
      'value' : '',
      'type'  : 'password',
      'title' : 'New Password',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:left',
      'note'  : ''
    },
    'againpassword' : {
      'input' : 'input',
      'value' : '',
      'type'  : 'password',
      'title' : 'Confirm New Password',
      'disabled': false,
      'style' : 'width:calc(50% - 10px); float:right',
      'note'  : ''
    },
    'update' : {
      'class': 'submit',
      'icon' : '',
      'text' : 'Update Changes',
      'style': ''
    }
  };
  
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    // public global: GlobalStore,
    // public translate: TranslateService,
  ) { }

  ngOnInit(): void {

  }

  selectTab(index:number)
  {
    this.selectedTabIndex = index;
  }
  

}

