import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { MaterialModule } from './material.module';
// import { TranslateModule } from '@ngx-translate/core';

// layout

// module
import { SwiperModule } from 'swiper/angular';
import { NgxEditorModule } from 'ngx-editor';
import { ImageCropperModule } from 'ngx-image-cropper';

// component
import { DialogBox } from './components/dialog/dialog.component';
import { InputBox } from './components/input-box/inputbox.component';
import { ButtonBox } from './components/button/button.component';
import { TableList } from './components/table/table.component';
import { ImageEditor } from './components/cropper/cropper.component';
import { ImageCompress } from './components/compressor/compressor.component';

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
SwiperCore.use([Navigation, Pagination, Autoplay]);


@NgModule({
  imports: [
    CommonModule,
    SwiperModule,
    FormsModule,
    NgxEditorModule,
    ImageCropperModule,
    // TranslateModule
  ],
  exports: [
    // TranslateModule
    FormsModule,
    SwiperModule,
    NgxEditorModule,
    ImageCropperModule,
    
    InputBox,
    ButtonBox,
    TableList,
    DialogBox,
    ImageEditor,
    ImageCompress
  ],
  declarations: [
    InputBox,
    ButtonBox,
    TableList,
    DialogBox,
    ImageEditor,
    ImageCompress,
  ],
  providers: [
    
  ],
})
export class SharedModule { }
