import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';
import { VersionLog } from 'src/app/service/log.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'main-layout',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainLayout implements OnInit {

  constructor(
    public global : GlobalStore,
    private route: ActivatedRoute,
    public router: Router,
    private ApiService: ApiService,
    public version: VersionLog
  ) {
    this.router.events.subscribe((event:any) => 
    {   
      if(event instanceof NavigationEnd)
      {
        // check on login status
        if(event.url.includes('?'))
        {
          let url = event.url.split('?')[0];
          if(url !== '/login')
          {
            this.checkStatus();
          }
        }
        else
        {
          if(event.url !== '/login')
          {
            this.checkStatus();
          }
        }
      } 
    });
  }

  'menuData' : any = [
    {
      'title' : '用户',
      'code'  : 'menu.member',
      'route' : '/memberlist',
      'icon'  : 'person'
    },
    {
      'title' : '首页横幅',
      'code'  : 'menu.banner',
      'route' : '/bannerlist',
      'icon'  : 'tab'
    },
    {
      'title' : '视频',
      'code'  : 'menu.videolist',
      'route' : '/videolist',
      'icon'  : 'videocam'
    },
    {
      'title' : '直播',
      'code'  : 'menu.streamlist',
      'route' : '/streamlist',
      'icon'  : 'radio_button_checked'
    },
    {
      'title' : '优惠活动',
      'code'  : 'menu.activities',
      'route' : '/activitieslist',
      'icon'  : 'calendar_month'
    },
    {
      'title' : '个人挑战',
      'code'  : 'menu.challengelist',
      'route' : '/challengelist',
      'icon'  : 'emoji_events'
    },
    {
      'title' : '客服链接',
      'code'  : 'menu.cslist',
      'route' : '/cslist',
      'icon'  : 'support_agent'
    },
    {
      'title' : '用户设置',
      'code'  : 'menu.setting',
      'route' : '/setting',
      'icon'  : 'toggle_off'
    }
  ];
  'swiperConfig' : SwiperOptions = {
    'slidesPerView': 2,
    'spaceBetween': 20,
    'navigation': false,
    'loop' : true,
    'autoplay' : {
      'delay' : 5000
    }
  };
  'versionNote' : any = null;
  'newUpdate' : boolean = false;
  'versionExpand' : boolean = false;

  ngOnInit(): void {
    this.getVersionLog();
  }

  getVersionLog()
  {
    let length = this.version.log.length;
    this.versionNote = this.version.log[length - 1];
    let versionNum = localStorage.getItem('version_num') || '';

    if(versionNum !== this.versionNote.version)
    {
      this.newUpdate = true;
      localStorage.setItem('version_num', `${this.versionNote.version}`);
    }
  }
  seeMoreVersion()
  {
    this.versionExpand = !this.versionExpand;
    let mainbox : any = document.querySelector('.versionbox > .boxcover');
    let box : any = document.querySelector('.versionbox');

    if(this.versionExpand)
    {
      if(mainbox && box)
      {
        box.style.height = `${mainbox.getBoundingClientRect().height + 40}px`;
      }
    }
    else
    {
      if(mainbox && box)
      {
        box.style.height = `180px`;
      }
    }
  }

  checkStatus()
  {
    this.global.getUserInfo((res:any) =>
    {
      
    }, (error:any) =>
    {
      localStorage.removeItem('token');
      localStorage.removeItem('userinfo');
      window.location.href = '/login';

      // this.global.dialog = {
      //   'type'    : 'error', //  error, success, warn
      //   'data'    : [error.error.message], // text, array in format
      //   'action'  : '', // action button text
      //   'cancel'  : '确认', // cancel button text
      //   'callback': null, // action on click function
      //   // 'callback': (): void => {
      //   //   this.refreshPage();
      //   // },
      // };
    });
  }

  navigate(route:string)
  {
    window.location.href = route;
  }
}

