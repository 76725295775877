import { Injectable } from '@angular/core';

@Injectable()
export class VersionLog {
  constructor(
  ) { }

  'log' : any  = [
    {
      'version' : '0.0.1',
      'note'    : [
        'added dashboard',
        'added inventory',
        'added report and statistic',
        'added component (input-box, button, loader)'
      ]
    },
    {
      'version' : '0.0.2',
      'note'    : [
        'added customer',
        'refine component input-box : added props require, added emit',
        'added newsletter'
      ]
    },
    {
      'version' : '0.0.3',
      'note'    : [
        'added register form',
        'refine component input-box (option & variable)',
        'added user'
      ]
    },
    {
      'version' : '0.0.4',
      'note'    : [
        'refine inventory',
        'added product / add product',
        'refine button component'
      ]
    },
    {
      'version' : '0.0.5',
      'note'    : [
        'fixed inventory page, added "add category" & "add product" button ',
        'set inventory images, detect if current products have more then 1 image'
      ]
    },
    {
      'version' : '0.0.6',
      'note'    : [
        'added new pages (profile page, user page, support page)',
        'fixed inventory page with table component',
        'added best sell product report'
      ]
    },
    {
      'version' : '0.0.7',
      'note'    : [
        'added category page',
        'refine product page, into variant form',
        'added login page'
      ]
    },
    {
      'version' : '1.1.3',
      'note'    : [
        {
          'title' : '修改页面重定向',
          'list' : ['优化页面样式', '优化页面菜单样式']
        },
        {
          'title' : '优化页面功能，添加搜索功能',
          'list' : ['客服端用户', '后端用户', '直播', '视频']
        },
        {
          'title' : '直播详情页面',
          'list' : ['添加第三方植入拉流地址 (HLS, FLV)', '添加删除功能']
        },
        {
          'title' : '添加版本号和版本功能详情'
        }
      ]
    },
    {
      'version' : '1.1.4',
      'note'    : [
        {
          'title' : '列表页面添加标签显示',
          'list' : ['直播列表', '视频列表', '短视频列表', '大师教学列表']
        },
        {
          'title' : '优化编制页面',
          'list' : ['添加选项，可以编制下一个还是回到列表', '回到列表现在可以回到去之前页面']
        },
        {
          'title' : '优化标签页面',
          'list' : ['可以同个时间直接创建多个标签', '优化列表样式']
        }
      ]
    },
    {
      'version' : '1.1.5',
      'note'    : [
        {
          'title' : '调整直播更新功能',
          'list' : ['修改更新直播开播时间']
        },
        {
          'title' : '添加视频删除功能',
          'list' : ['视频，短视频，大师教学']
        },
        {
          'title' : '调整用户更新功能',
          'list' : ['修改更新密码']
        }
      ]
    },
    {
      'version' : '1.1.7',
      'note'    : [
        {
          'title' : '修改短视频封面逻辑',
          'list' : ['现在可以选择横屏还是竖屏封面']
        },
        {
          'title' : '优化横幅页面',
          'list' : ['直播，视频，图片选项', '修改排序逻辑']
        },
        {
          'title' : '添加敏感字眼',
          'list' : ['添加页面，目前逻辑还未完善']
        }
      ]
    },
    {
      'version' : '1.1.8',
      'note'    : [
        {
          'title' : '登录页面',
          'list' : ['现在可以快速 Enter 登录']
        },
        {
          'title' : '添加黑名单页面',
          'list' : ['添加，删除功能']
        },
        {
          'title' : '调整 dropdown 搜索功能',
          'list' : ['现在可以多元化搜索任何字眼']
        }
      ]
    }
  ]
}