import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/service/api.service';
import { GlobalStore } from 'src/app/service/global.service';

// import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './whitelist.page.html',
  styleUrls: ['./whitelist.page.scss']
})
export class WhitelistPage implements OnInit {

  'whiteData':  any = [];
  'tableData' : any = [
    {
      'class' : 'id',
      'type'  : 'text',
      'text'  : '直播ID',
      'code ' : '',
      'style' : {
        'width' : '54px'
      },
      'button': []
    },
    {
      'class' : 'img_url',
      'type'  : 'image',
      'data'  : {
        'template' : 'videocover'
      },
      'text'  : '',
      'code'  : '',
      'style' : '',
      'button': []
    },
    {
      'class' : 'streamername',
      'type'  : 'text',
      'text'  : '主播',
      'code'  : '',
      'style' : '',
      'button': []
    },
    {
      'class' : 'statusname',
      'type'  : 'text',
      'text'  : '状态',
      'code'  : '',
      'style' : {
        'white-space' : 'nowrap'
      },
      'button': []
    },
    {
      'class' : 'scheduled_at',
      'type'  : 'text',
      'text'  : '结束时间',
      'code'  : '',
      'style' : {
        'white-space' : 'nowrap',
        'text-align' : 'center'
      },
      'button': []
    },
    {
      'class' : 'action',
      'type'  : 'action',
      'text'  : '',
      'code'  : '',
      'style' : {
        'width' : '80px'
      },
      'button': [
        {
          'data' : {
            'title'   : '编辑',
            'class'   : '',
            'icon'    : 'edit_note',
            'text'    : '',
            'style'   : 'background:#e2e3e8',
            'callback': (data: any): void => {
              window.location.href = `/stream?id=${data.id}`
            }
          },
          'disabled': (data:any) => {
            return false;
          }
        }
      ]
    }
  ];
  'pagData' : any = null;
  'button' : any = {
    'create' : {
      'data' : {
        'class': 'normal',
        'icon' : 'add',
        'text' : '创建直播',
        'style': ''
      },
      'disabled': false
    },
  }

  'sampleData' : any = [
    {
      'id'          : 1,
      'streamername': 'streamer01',
      'statusname'  : '即将直播',
      'starttime'   : '2023-06-10 12:35:55',
      'endtime'     : '2023-06-12 10:10:10',
      'title'       : '服每歡占音行在？您尤卜足着新才，七娘時開助親了麻免耍耳且又門昌還即快扒工、光昔助室直很說瓜造以細'
    },
    {
      'id'          : 1,
      'streamername': 'streamer01',
      'statusname'  : '即将直播',
      'starttime'   : '2023-06-10 12:35:55',
      'endtime'     : '2023-06-12 10:10:10',
      'title'       : '服每歡占音行在？您尤卜足着新才，七娘時開助親了麻免耍耳且又門昌還即快扒工、光昔助室直很說瓜造以細'
    },
    {
      'id'          : 1,
      'streamername': 'streamer01',
      'statusname'  : '即将直播',
      'starttime'   : '2023-06-10 12:35:55',
      'endtime'     : '2023-06-12 10:10:10',
      'title'       : '服每歡占音行在？您尤卜足着新才，七娘時開助親了麻免耍耳且又門昌還即快扒工、光昔助室直很說瓜造以細'
    },
    {
      'id'          : 1,
      'streamername': 'streamer01',
      'statusname'  : '即将直播',
      'starttime'   : '2023-06-10 12:35:55',
      'endtime'     : '2023-06-12 10:10:10',
      'title'       : '服每歡占音行在？您尤卜足着新才，七娘時開助親了麻免耍耳且又門昌還即快扒工、光昔助室直很說瓜造以細'
    },
    {
      'id'          : 1,
      'streamername': 'streamer01',
      'statusname'  : '即将直播',
      'starttime'   : '2023-06-10 12:35:55',
      'endtime'     : '2023-06-12 10:10:10',
      'title'       : '服每歡占音行在？您尤卜足着新才，七娘時開助親了麻免耍耳且又門昌還即快扒工、光昔助室直很說瓜造以細'
    },
    {
      'id'          : 1,
      'streamername': 'streamer01',
      'statusname'  : '即将直播',
      'starttime'   : '2023-06-10 12:35:55',
      'endtime'     : '2023-06-12 10:10:10',
      'title'       : '服每歡占音行在？您尤卜足着新才，七娘時開助親了麻免耍耳且又門昌還即快扒工、光昔助室直很說瓜造以細'
    }
  ];
  
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private ApiService: ApiService,
    public global: GlobalStore,
    // public translate: TranslateService,
  ) { }

  ngOnInit(): void {

    this.getWhiteList();

    this.ApiService.addWhiteList({
      'country_id' : 1,
      'category' : 'wpt'
    }).subscribe(
      (res: any) => {
        if(res.data)
        {
          
        }
      },
      (error:any) =>
      {
        this.global.dialog = {
          'type'    : 'error',
          'data'    : [`${error.error.message}`],
          'action'  : '',
          'cancel'  : '确认',
          'callback': null
        };
      }
    );
  }

  getWhiteList()
  {
    this.whiteData = [];
    this.ApiService.getWhiteList({}).subscribe(
      (res: any) => {
        if(res.data)
        {
          let arr = JSON.parse(JSON.stringify(res.data));
          arr.map((item:any, index:number) =>
          {
            let dataObj = JSON.parse(JSON.stringify(item));
            dataObj.streamername = item.streamer.name;
            
            this.whiteData.push(dataObj);
          });

          this.pagData = JSON.parse(JSON.stringify(res.meta));
        }
      },
      (error:any) =>
      {
        this.global.dialog = {
          'type'    : 'error',
          'data'    : [`${error.error.message}`],
          'action'  : '',
          'cancel'  : '确认',
          'callback': null
        };
      }
    );
  }

  navigate(route:string)
  {
    window.location.href = `${route}`;
  }

  add()
  {
    window.location.href = `/stream`;
  }

  

}

