import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxImageCompressService, DataUrl } from 'ngx-image-compress';

@Component({
  selector: 'image-editor',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})

export class ImageEditor implements OnInit {
  @Input() ratio : number = 1/1;
  @Input() maintainRatio : boolean = true;
  @Input()
  set data(file:any) 
  {
    if(file)
    {
      this.setData(file);
    }
    else
    {
      this.fileData = null;
      this.cropperFile = '';
    }
  }
  
  constructor(
    private imageCompress: NgxImageCompressService
  ) { }

  'fileData' : any = null;
  'button' : any = {
    'cancel' : {
      'data' : {
        'class': '',
        'icon' : '',
        'text' : '取消',
        'style': 'width:calc(50% - 10px); float:left;'
      },
      'disabled': false
    },
    'submit' : {
      'data' : {
        'class': 'normal',
        'icon' : '',
        'text' : '提交图片',
        'style': 'width:calc(50% - 10px); float:right;'
      },
      'disabled': false
    }
  };
  'cropperFile' : any = '';

  ngOnInit(): void {
    
  }

  setData(file:any)
  {
    this.fileData = file;
  }

  loadImageFailed()
  {

  }
  imageCropped(event: ImageCroppedEvent)
  {
    this.cropperFile = event.base64;
  }


  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  cancel()
  {
    this.onCancel.emit();
  }
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  submit()
  {
    let base64 = this.cropperFile;

    fetch(base64)
    .then(response => response.blob())
    .then(blob => 
    {
        let file = new File([blob], 'image.png', {type: blob.type});
        this.onSubmit.emit(file);
        this.onCancel.emit();
    })
  }

}