<div class="cropper-display" *ngIf="fileData">
  <div class="overlay"></div>
  <div class="dialogcover">
      <image-cropper
          [imageChangedEvent]="fileData"
          [maintainAspectRatio]="maintainRatio"
          [aspectRatio]="ratio"
          [format]="'png'"
          (imageCropped)="imageCropped($event)"
          (loadImageFailed)="loadImageFailed()"
      ></image-cropper>

      <div class="action">
        <button-box [data]="button.cancel.data" (click)="cancel()"></button-box>
        <button-box [data]="button.submit.data" (click)="submit()"></button-box>
      </div>
  </div>
</div>