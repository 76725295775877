<div class="configurationcover">
    <div class="head">
    </div>
    <div class="content">
        <div class="logo">
            <div class="img">
                <button-box [data]="formData.logo"></button-box>
            </div>
        </div>
        <div class="form">
            <input-box [data]="formData.maintainance"></input-box>
            <input-box [data]="formData.theme"></input-box>

            <div class="clear-row"></div>
            
            <input-box [data]="formData.language"></input-box> 

            <div class="clear-row"></div>

            <input-box [data]="formData.starttime"></input-box>
            <input-box [data]="formData.endtime"></input-box>

            <div class="clear-row"></div>

            <input-box [data]="formData.address"></input-box>

            <div class="clear-row"></div>

            <input-box [data]="formData.mobile"></input-box>
            <input-box [data]="formData.email"></input-box>

            <div class="clear-row"></div>

            <input-box [data]="formData.desc"></input-box>

            <div class="clear-row"></div>

            <input-box [data]="formData.password"></input-box>

            <div class="clear-row"></div>

            <input-box [data]="formData.newpassword"></input-box>
            <input-box [data]="formData.againpassword"></input-box>

            <div class="clear-row"></div>

            <button-box [data]="formData.update"></button-box>
        </div>

        <div class="clear-row"></div>
    </div>
</div>