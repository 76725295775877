import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dialog-box',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})

export class DialogBox implements OnInit {
  @Input()
  set data(obj:any)
  {
    if(obj)
    {
      this.initData(obj);
    }
    else
    {
      this.dataObj = null;
    }
  }

  'dataObj' : any = null;
  'button' : any = {
    'cancel' : {
      'data' : {
        'class': '',
        'icon' : '',
        'text' : '',
        'style': 'width:100%'
      },
      'disabled': false
    },
    'action' : {
      'data' : {
        'class': 'normal',
        'icon' : '',
        'text' : '',
        'style': 'width:100%;'
      },
      'disabled': false
    }
  };

  constructor(

  ) { }

  ngOnInit(): void {
    if(this.data)
    {
      this.initData(this.data);
    }
  }

  initData(obj:any)
  {
    this.dataObj = obj;

    if(obj.cancel)
    {
      this.button.cancel.data.text = obj.cancel;
    }
    if(obj.action)
    {
      this.button.action.data.text = obj.action;
    }
    
  }

  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  cancel()
  {
    if(this.dataObj.cancel && this.dataObj.return)
    {
      this.dataObj.return();
    }
    else
    {
      this.onCancel.emit();
    }
    
  }

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  action()
  {
    if(this.dataObj.action && this.dataObj.callback)
    {
      this.dataObj.callback();
    }
  }
}