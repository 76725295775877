import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NgxImageCompressService, DataUrl } from 'ngx-image-compress';

import { GlobalStore } from 'src/app/service/global.service';

@Component({
  selector: 'image-compress',
  templateUrl: './compressor.component.html',
  styleUrls: ['./compressor.component.scss'],
})

export class ImageCompress implements OnInit {
  @Input()
  set style(value:string) 
  {
    if(value)
    {
      this.button.upload = {
        'data' : {
          'class': '',
          'icon' : '',
          'text' : '上传图片',
          'style': value,
          'title': '上传图片'
        },
        'disabled': false
      }
    }
  }
  
  constructor(
    private imageCompress: NgxImageCompressService,
    public global : GlobalStore
  ) { }

  'button' : any = {
    'upload' : {
      'data' : {
        'class': '',
        'icon' : '',
        'text' : '上传图片',
        'style': ''
      },
      'disabled': false
    }
  };
  'cropperFile' : any = '';

  ngOnInit(): void {
    
  }

  @Output() onUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();
  upload()
  {
    this.imageCompress.uploadAndGetImageWithMaxSize(4, true).then(
      (result: DataUrl) => 
      {
        // success
        this.onUpload.emit(result);
      },
      (result: string) => 
      {
        // failed
        let size = (this.imageCompress.byteCount(result) / 1024) / 1000
        this.onError.emit(size.toFixed(2));
      }
    );
  }

}