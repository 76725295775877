<div class="sidemenu" *ngIf="!(router.url.includes('/login'))">
    <div class="menucover">
        <div class="logo" (click)="navigate('/')">
            <div class="icon"></div>
            <span>WPTGLOBAL</span>
        </div>

        <div class="menubox" *ngFor="let menu of menuData; index as menuindex;">
            <div class="box" [class]="{'active' : router.url.includes(menu.route)}" (click)="navigate(menu.route)">
                <i class="iconfont">{{ menu.icon }}</i>
                {{ menu.title }}
            </div>
        </div>
        <div class="menubox logout">
            <div class="box">
                <i class="iconfont">meeting_room</i>
                退出
            </div>
        </div>
    </div>
</div>
<div class="body" [class]="{'logon' : router.url.includes('/login')}">
    <div class="container">
        <div class="header" *ngIf="!(router.url.includes('/login'))">
            <div class="title">欢迎来到 WPTMY 后台控制中心</div>
            <span>内容修改，查看大数据，数据分析，用户管理</span>
          
            <div class="action">
              <!-- <div class="notification"><i class="iconfont">notifications_none</i></div> -->
              <div class="profile"><i class="iconfont">person_outline</i></div>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
<div class="widget" *ngIf="!(router.url.includes('/login'))">
    <div class="title">实时动态</div>
    <div class="versionbox" [class]="{'active' : newUpdate}" (click)="seeMoreVersion()">
        <span></span>
        <div class="boxcover">
            <div class="title">版本号 : {{ versionNote.version }}</div>
            <div class="list">
                <div class="listbox" *ngFor="let main of versionNote.note; index as mainindex;">
                    <p>{{ mainindex + 1 }}. {{ main.title }}</p>
                    <ul>
                        <li *ngFor="let sub of main.list; index as subindex;">{{ sub }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>