import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { Editor, Toolbar } from 'ngx-editor';
import { GlobalStore } from 'src/app/service/global.service';

@Component({
  selector: 'input-box',
  templateUrl: './inputbox.component.html',
  styleUrls: ['./inputbox.component.scss'],
})

export class InputBox implements OnInit {
  @Output() onInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPress: EventEmitter<any> = new EventEmitter<any>();

  @Input() data : any = '';
  @Input() press : boolean = false;
  @Input() value: any = '';
  @Input() option: any = '';

  @Input() error : string = '';
  @Input() disabled : boolean = false;

  constructor(
    private clipboard: Clipboard,
    public global: GlobalStore
  ) { }

  // type : 'input', 'textarea', 'checkbox', 'dropdown', 'select', 'multiselect', 'editor', 'reader'
  'dataObj' : any = null;
  'inputValue' : any = '';
  'dropDownValue' : any = '';
  'selectorValue' : any = '';
  'dataOption' : any = [];
  'listOption' : any = [];
  'selectedOption' : any = [];

  'visibility': boolean = false;
  'lightboxDropdown' : boolean = false;
  'searchedDropdown' : string = '';
  'lightboxSelector' : boolean = false;
  'searchedSelector' : string = '';
  'position' : any = null;

  'editor' : any = null;
  'toolbar' : Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['bullet_list'],
    ['link']
  ];

  ngOnInit(): void {

    window.addEventListener('click', () =>
    {
      this.lightboxDropdown = false;
      this.lightboxSelector = false;
    });

    this.editor = new Editor();
  }
  ngOnDestroy(): void {
    window.removeEventListener('click', () => {});
    this.editor.destroy();
  }
  ngOnChanges(changes:SimpleChanges) {
    if(this.data)
    {
      this.setData(this.data);
    }
  }

  lightboxOnClick(event:any)
  {
    event.stopPropagation();
  }
  clearSearch()
  {
    this.searchedDropdown = '';
    this.searchedSelector = '';
  }
  keyPress(event:any)
  {
    if(this.press && event.keyCode == 13 && event.key === 'Enter')
    {
      this.onPress.emit();
    }
  }

  // set data from prop
  setData(obj:any)
  {
    this.dataObj = JSON.parse(JSON.stringify(obj));
    if(this.dataObj.option && this.dataObj.option.length > 0)
    {
      this.setOption(this.dataObj.option)
    }
    else
    {
      this.setValue(this.value);
    }
  }
  setValue(value:any)
  {
    this.inputValue = value;
  }
  setOption(option:any)
  {
    this.dataOption = JSON.parse(JSON.stringify(option));
    this.setValue(this.value);
    if(this.dataObj.input === 'select')
    {
      this.initSelector();
    }
    else if(this.dataObj.input === 'dropdown')
    {
      this.initDropDown();
    }
  }

  // dropdown
  initDropDown()
  {
    console.warn(this.dataObj)
    if(this.inputValue)
    {
      this.dataOption.map((item:any) =>
      {
        if(item.value === this.inputValue)
        {
          this.dropDownValue = item.name
        }
      })
    }
    else
    {
      this.dropDownValue = '';
    }
  }
  toggleDropdown(event:any)
  {
    event.stopPropagation();
    this.position = {};
    this.lightboxDropdown = !this.lightboxDropdown;
    let dbox : any = document.querySelector(`.input-box.${this.dataObj.class} > .inputcover > .dropdown`);
    if(dbox)
    {
      let data = dbox.getBoundingClientRect();
      let sheight = window.innerHeight;
      if(data)
      {
        this.position.width = `${data.width}px`;
        if(data.bottom + 250 >= sheight)
        {
          this.position.bottom = `${sheight - data.top}px`
        }
        else
        {
          this.position.top = `${data.top + data.height}px`
        }

        this.position.left = `${data.left}px`
      }
    }
  }
  selectDropdownOption(option:any)
  {
    this.lightboxDropdown = false;
    this.position = null;
    this.onInput.emit(option.value);
  }

  // selector
  initSelector()
  {
    this.selectedOption = [];
    
    if(this.inputValue)
    {
      this.selectorValue = '';
      this.listOption = this.dataOption.filter((el:any) => {
        return this.inputValue.findIndex((x:any) => x.value === el.value) < 0
      });
      this.selectedOption = this.dataOption.filter((el:any) => {
        return this.inputValue.findIndex((x:any) => x.value === el.value) > -1
      });
      this.selectedOption.map((item:any, index:number) =>
      {
        if(index === 0)
        {
          this.selectorValue += `${item.name}`
        }
        else
        {
          this.selectorValue += `, ${item.name}`
        }
      })
    }
    else
    {
      this.selectorValue = '';
      this.listOption = JSON.parse(JSON.stringify(this.dataOption));
      this.selectedOption = [];
    }
  }
  toggleSelector(event:any)
  {
    event.stopPropagation();
    this.position = {};
    this.lightboxSelector = !this.lightboxSelector;
    let dbox : any = document.querySelector(`.input-box.${this.dataObj.class} > .inputcover > .selector`);
    if(dbox)
    {
      let data = dbox.getBoundingClientRect();
      let sheight = window.innerHeight;
      if(data)
      {
        this.position.width = `480px`;
        if(data.bottom + 360 >= sheight)
        {
          this.position.bottom = `${sheight - data.top}px`
        }
        else
        {
          this.position.top = `${data.top + data.height}px`
        }

        this.position.left = `${data.left}px`
      }
    }
  }
  selectSelectorOption(option:any)
  {
    let index = this.listOption.findIndex((x:any) => x.value === option.value);
    if(index > -1)
    {
      this.selectedOption.push(option);
      this.listOption.splice(index, 1);
    }
  }
  removeSelectedOption(option:any)
  {
    let index = this.selectedOption.findIndex((x:any) => x.value === option.value);
    if(index > -1)
    {
      this.selectedOption.splice(index, 1);
      this.listOption.push(option);
    }
  }
  clearAllOption()
  {
    this.listOption = JSON.parse(JSON.stringify(this.dataOption));
    this.selectedOption = [];
  }
  confirmSelect()
  {
    this.inputValue = this.selectedOption;
    this.onInput.emit(this.inputValue);
    this.lightboxSelector= false;
  }
  searchSelector(event:any)
  {
    console.warn(this.listOption);
  }
  searchDrop(event:any)
  {
    console.warn(this.searchedDropdown)
  }

  // password
  toggleVisibility()
  {
    this.visibility = !this.visibility;
  }

  // text input
  inputOnChange(event:any)
  {
    this.onInput.emit(this.inputValue);
  }
  inputOnEditor(event:any)
  {
    this.onInput.emit(this.inputValue);
  }
  copyText()
  {
    this.clipboard.copy(this.inputValue);
    this.global.dialog = {
      'type'    : 'success',
      'data'    : [`${this.dataObj.title} 已成功复制！`],
      'action'  : '',
      'cancel'  : '确认',
      'callback': null
    };
  }

  // checkbox
  inputOnCheck()
  {
    this.inputValue = !this.inputValue;
    this.onInput.emit(this.inputValue);
  }

  // switch
  inputOnSwitch()
  {
    this.inputValue = !this.inputValue;
    this.onInput.emit(this.inputValue);
  }

  // upload
  inputOnUpload(event:any)
  {
    this.onUpload.emit(event);
  }

  
}