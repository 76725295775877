import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'table-list',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class TableList implements OnInit {
  @Output() onPag: EventEmitter<any> = new EventEmitter<any>();

  @Input() col : any = [];
  @Input() data : any = [];
  @Input()
  set pagi(pag:any)
  {
    if(pag)
    {
      this.setPagination(pag);
    }
  }

  'button' : any = {
    'prevmax': {
      'data' : {
        'class'   : '',
        'icon'    : 'keyboard_double_arrow_left',
        'text'    : '',
        'style'   : 'background: #ffffff;'
      },
      'disabled' : false
    },
    'prev': {
      'data' : {
        'class'   : '',
        'icon'    : 'chevron_left',
        'text'    : '',
        'style'   : 'background: #ffffff;'
      },
      'disabled' : false
    },
    'next': {
      'data' : {
        'class'   : '',
        'icon'    : 'chevron_right',
        'text'    : '',
        'style'   : 'background: #ffffff;'
      },
      'disabled' : false
    },
    'nextmax': {
      'data' : {
        'class'   : '',
        'icon'    : 'keyboard_double_arrow_right',
        'text'    : '',
        'style'   : 'background: #ffffff;'
      },
      'disabled' : false
    }
  };
  'pagArr' : any = [];
  'selectedPag' : number = 1;
  'swiperConfig' : SwiperOptions = {
    'slidesPerView': 'auto',
    'spaceBetween': 0,
    'navigation': false,
    'loop' : false
  };

  constructor(

  ) { }

  ngOnInit(): void {
  
  }

  setPagination(pag:any)
  {
    this.pagArr = [];
    if(pag.total > pag.per_page)
    {
      let num = Math.ceil(pag.total/pag.per_page);
      for(let i = 1; i <= num; i++)
      {
        this.pagArr.push({
          'data' : {
            'class': '',
            'icon' : '',
            'text' : `${i}`,
            'style': 'background: #ffffff;'
          },
          'disabled': false
        })
      }

      this.selectedPag = pag.current_page;
    }
  }
  selectPage(page:number)
  {
    this.selectedPag = page;
    this.onPag.emit(this.selectedPag);
  }
  prevPage()
  {

  }
  nextPage()
  {

  }

  onClick(button:any, data:any)
  {
    button.data.callback(data);
  }

  @Output() onDetail: EventEmitter<any> = new EventEmitter<any>();
  goDetail(id:any)
  {
    this.onDetail.emit(id);
  }

  @Output() onCheck: EventEmitter<any> = new EventEmitter<any>();
  goCheck(index:number)
  {
    this.onCheck.emit(index);
  }

}