import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'button-box',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})

export class ButtonBox implements OnInit {
  @Input() data : any = null;
  @Input() disabled : boolean = false;
  
  constructor(

  ) { }

  ngOnInit(): void {
    
  }

}