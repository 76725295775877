import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// layout
import { BlankLayout } from './layout/blank/blank.component';
import { MainLayout } from './layout/main/main.component';

import { ProfilePage } from './pages/profile/profile.page';
import { ConfigurationPage } from './pages/configuration/configuration.page';
import { WhitelistPage } from './pages/whitelist/whitelist.page';

const routes: Routes = [
  {
    path: '',
    component: MainLayout,
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    component: BlankLayout,
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'memberlist',
    component: MainLayout,
    loadChildren: () => import('./pages/memberlist/memberlist.module').then(m => m.MemberlistPageModule)
  },
  {
    path: 'member',
    component: MainLayout,
    loadChildren: () => import('./pages/member/member.module').then(m => m.MemberPageModule)
  },
  {
    path: 'userlist',
    component: MainLayout,
    loadChildren: () => import('./pages/userlist/userlist.module').then(m => m.UserlistPageModule)
  },
  {
    path: 'user',
    component: MainLayout,
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule)
  },
  {
    path: 'streamlist',
    component: MainLayout,
    loadChildren: () => import('./pages/streamlist/streamlist.module').then(m => m.StreamlistPageModule)
  },
  {
    path: 'stream',
    component: MainLayout,
    loadChildren: () => import('./pages/stream/stream.module').then(m => m.StreamPageModule)
  },
  {
    path: 'videolist',
    component: MainLayout,
    loadChildren: () => import('./pages/videolist/videolist.module').then(m => m.VideolistPageModule)
  },
  {
    path: 'video',
    component: MainLayout,
    loadChildren: () => import('./pages/video/video.module').then(m => m.VideoPageModule)
  },
  {
    path: 'shortlist',
    component: MainLayout,
    loadChildren: () => import('./pages/shortlist/shortlist.module').then(m => m.ShortlistPageModule)
  },
  {
    path: 'short',
    component: MainLayout,
    loadChildren: () => import('./pages/short/short.module').then(m => m.ShortPageModule)
  },
  {
    path: 'educationlist',
    component: MainLayout,
    loadChildren: () => import('./pages/educationlist/educationlist.module').then(m => m.EducationlistPageModule)
  },
  {
    path: 'education',
    component: MainLayout,
    loadChildren: () => import('./pages/education/education.module').then(m => m.EducationPageModule)
  },
  {
    path: 'taglist',
    component: MainLayout,
    loadChildren: () => import('./pages/taglist/taglist.module').then(m => m.TaglistPageModule)
  },
  {
    path: 'tag',
    component: MainLayout,
    loadChildren: () => import('./pages/tag/tag.module').then(m => m.TagPageModule)
  },
  {
    path: 'bannerlist',
    component: MainLayout,
    loadChildren: () => import('./pages/bannerlist/bannerlist.module').then(m => m.BannerlistPageModule)
  },
  {
    path: 'banner',
    component: MainLayout,
    loadChildren: () => import('./pages/banner/banner.module').then(m => m.BannerPageModule)
  },
  {
    path: 'activitieslist',
    component: MainLayout,
    loadChildren: () => import('./pages/activitieslist/activitieslist.module').then(m => m.ActivitieslistPageModule)
  },
  {
    path: 'activity',
    component: MainLayout,
    loadChildren: () => import('./pages/activity/activity.module').then(m => m.ActivityPageModule)
  },
  {
    path: 'wordslist',
    component: MainLayout,
    loadChildren: () => import('./pages/wordslist/wordslist.module').then(m => m.WordsListPageModule)
  },
  {
    path: 'words',
    component: MainLayout,
    loadChildren: () => import('./pages/words/words.module').then(m => m.WordsPageModule)
  },
  {
    path: 'blacklist',
    component: MainLayout,
    loadChildren: () => import('./pages/blacklist/blacklist.module').then(m => m.BlacklistPageModule)
  },
  {
    path: 'black',
    component: MainLayout,
    loadChildren: () => import('./pages/black/black.module').then(m => m.BlackPageModule)
  },
  {
    path: 'cslist',
    component: MainLayout,
    loadChildren: () => import('./pages/cslist/cslist.module').then(m => m.CslistPageModule)
  },
  {
    path: 'cs',
    component: MainLayout,
    loadChildren: () => import('./pages/cs/cs.module').then(m => m.CsPageModule)
  },
  {
    path: 'challengelist',
    component: MainLayout,
    loadChildren: () => import('./pages/challengelist/challengelist.module').then(m => m.ChallengelistPageModule)
  },
  {
    path: 'rank',
    component: MainLayout,
    loadChildren: () => import('./pages/rank/rank.module').then(m => m.RankPageModule)
  },
  {
    path: 'challenge',
    component: MainLayout,
    loadChildren: () => import('./pages/challenge/challenge.module').then(m => m.ChallengePageModule)
  },

  {
    path: 'whitelist',
    component: WhitelistPage,
    loadChildren: () => import('./pages/whitelist/whitelist.module').then(m => m.WhitelistPageModule)
  },
  {
    path: 'profile',
    component: ProfilePage,
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'configuration',
    component: ConfigurationPage,
    loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationPageModule)
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
