import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import * as qs from 'qs';

let nowDate = new Date();
let getNowDate = nowDate.getFullYear() + '-' + ((nowDate.getMonth() + 1) >= 10 ? (nowDate.getMonth() + 1) : '0' + (nowDate.getMonth() + 1)) + '-' + (nowDate.getDate() >= 10 ? nowDate.getDate() : '0' + nowDate.getDate()) + ' ' + (nowDate.getHours() >= 10 ? nowDate.getHours() : '0' + nowDate.getHours()) + ':' + (nowDate.getMinutes() >= 10 ? nowDate.getMinutes() : '0' + nowDate.getMinutes()) + ':' + (nowDate.getSeconds() >= 10 ? nowDate.getSeconds() : '0' + nowDate.getSeconds());

const httpOptions = () => ({
  headers: new HttpHeaders({ 
    'Accept'      : '*/*',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
    'timeZoneCode': `${Math.round(new Date().getTimezoneOffset() / 60)}`,
    'dateTime'    : getNowDate,
  })
});

const formDataHttpOption = () => ({
  headers: new HttpHeaders({ 
    'Accept'      : '*/*',
    'Access-Control-Allow-Origin' : '*',
    'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
    'timeZoneCode': `${Math.round(new Date().getTimezoneOffset() / 60)}`,
    'dateTime'    : getNowDate,
  })
});


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  'domain': string = '';

  constructor(
    private http: HttpClient
  ) {

    let location = window.location.href;
    if(location.indexOf('wptglobalmy.') !== -1)
    {
      this.domain = 'https://hub.wptglobalmy.com/back-api';
    }
    else
    {
      this.domain = 'https://wpt-api.pkfang.tech/back-api';
    }
  }

  // 登录
  loginUser(param:any)
  {
    return this.http.post(this.domain + '/auth/token', qs.stringify(param), httpOptions())
  }
  // 获取用户信息
  getUserInfo()
  {
    return this.http.get(this.domain + '/auth/user', httpOptions())
  }



  // 客户端用户接口
  // --------------------------------------------------
  // 获取客户端用户列表
  getMemberList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/front-users` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 创建客户端用户
  createMember(param:any)
  {
    return this.http.post(this.domain + `/front-users`, qs.stringify(param), httpOptions())
  }
  // 获取客户端用户信息
  getMemberDetail(param:any)
  {
    return this.http.get(this.domain + `/front-users/${param.id}`, httpOptions())
  }
  // 更新客户端用户信息
  updateMemberDetail(param:any)
  {
    return this.http.patch(this.domain + `/front-users/${param.id}`, qs.stringify(param.data), httpOptions())
  }
  // 禁用客户端用户
  banMember(param:any)
  {
    return this.http.put(this.domain + `/front-users/${param.id}/banned`, qs.stringify(param), httpOptions())
  }
  // 开启客户端用户
  unbanMember(param:any)
  {
    return this.http.delete(this.domain + `/front-users/${param.id}/banned`, httpOptions())
  }




  // 后台用户接口
  // ------------------------------------------
  // 获取后台用户列表
  getUserList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/back-users` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 创建后端用户
  createUser(param:any)
  {
    return this.http.post(this.domain + `/back-users`, qs.stringify(param), httpOptions())
  }
  // 获取后端用户信息
  getUserDetail(param:any)
  {
    return this.http.get(this.domain + `/back-users/${param.id}`, httpOptions())
  }
  // 更新后端用户信息
  updateUserDetail(param:any)
  {
    return this.http.patch(this.domain + `/back-users/${param.id}`, qs.stringify(param.data), httpOptions())
  }
  // 禁用后端用户
  banUser(param:any)
  {
    return this.http.put(this.domain + `/back-users/${param.id}/banned`, qs.stringify(param), httpOptions())
  }
  // 开启后端用户
  unbanUser(param:any)
  {
    return this.http.delete(this.domain + `/back-users/${param.id}/banned`, httpOptions())
  }







  // 用户权限接口
  // ------------------------------------------
  // 获取用户权限列表
  getRoleList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/permission/roles` + (queryString ? `?${queryString}` : ''), httpOptions())
  }






  // 直播接口
  // ------------------------------------------
  // 获取直播列表
  getStreamList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/live-streams` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取直播信息
  getStreamDetail(param:any)
  {
    return this.http.get(this.domain + `/live-streams/${param.id}`, httpOptions())
  }
  // 创建直播
  createStream(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param);
    objArr.map((item:any) =>
    {
      if(item !== 'tags')
      {
        formData.append(item, param[item]);
      }
      else
      {
        for(let i = 0; i < param.tags.length; i++)
        {
          formData.append(`tags[]`, param.tags[i]);
        }
      }
    });

    return this.http.post(this.domain + `/live-streams`, formData, formDataHttpOption())
  }
  // 更新直播
  updateStream(param:any)
  {
    let formData : any = new FormData();
    formData.append('_method', 'patch');

    let objArr = Object.keys(param.data);
    objArr.map((item:any) =>
    {
      if(item !== 'tags')
      {
        formData.append(item, param.data[item]);
      }
      else
      {
        for(let i = 0; i < param.data.tags.length; i++)
        {
          formData.append(`tags[]`, param.data.tags[i]);
        }
      }
    });
    return this.http.post(this.domain + `/live-streams/${param.id}`, formData, formDataHttpOption())
  }
  // 开启直播
  onLiveStream(param:any)
  {
    return this.http.put(this.domain + `/live-streams/${param.id}/active`, qs.stringify(param), httpOptions())
  }
  // 关闭直播
  offLiveStream(param:any)
  {
    return this.http.put(this.domain + `/live-streams/${param.id}/completed`, qs.stringify(param), httpOptions())
  }







  // 客服链接接口
  // ------------------------------------------
  // 获取客服列表
  getCsList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
      return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/whatsapp-settings` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 创建客服链接
  createCs(param:any)
  {
    return this.http.post(this.domain + `/whatsapp-settings/store`, qs.stringify(param), httpOptions())
  }
  // 删除客服链接
  removeCs(param:any)
  {
    return this.http.post(this.domain + `/whatsapp-settings/${param.id}/delete`, qs.stringify(param), httpOptions())
  }
  // 更新客服链接
  updateCs(param:any)
  {
    return this.http.post(this.domain + `/whatsapp-settings/${param.id}/update`, qs.stringify(param.data), httpOptions())
  }








  // 挑战接口
  // ------------------------------------------
  // 获取排行列表
  getRankList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
      return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/ranks` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取排行信息
  getRankDetail(param:any)
  {
    return this.http.get(this.domain + `/ranks/${param.id}`, httpOptions())
  }
  // 创建排行
  createRank(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param);
    objArr.map((item:any) =>
    {
      formData.append(item, param[item]);
    });

    return this.http.post(this.domain + `/ranks/store`, formData, formDataHttpOption())
  }
  // 删除排行
  deleteRank(param:any)
  {
    return this.http.post(this.domain + `/ranks/${param.id}/delete`, qs.stringify(param), httpOptions())
  }
  // 更新排行
  updateRank(param:any)
  {
    return this.http.post(this.domain + `/ranks/${param.id}/update`, qs.stringify(param.data), httpOptions())
  }
  // 获取挑战列表
  getChallengeList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
      return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/challenges` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取挑战信息
  getChallengeDetail(param:any)
  {
    return this.http.get(this.domain + `/challenges/${param.id}`, httpOptions())
  }
  // 创建挑战
  createChallenge(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param);
    objArr.map((item:any) =>
    {
      formData.append(item, param[item]);
    });

    return this.http.post(this.domain + `/challenges/store`, formData, formDataHttpOption())
  }
  // 删除挑战
  deleteChallenge(param:any)
  {
    return this.http.post(this.domain + `/challenges/${param.id}/delete`, qs.stringify(param), httpOptions())
  }
  // 更新挑战
  updateChallenge(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param.data);
    objArr.map((item:any) =>
    {
      formData.append(item, param.data[item]);
    });

    return this.http.post(this.domain + `/challenges/${param.id}/update`, formData, formDataHttpOption())
  }







  // 视频接口
  // ------------------------------------------
  // 获取视频列表
  getVideoList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/videos` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取视频信息
  getVideoDetail(param:any)
  {
    return this.http.get(this.domain + `/videos/${param.id}`, httpOptions())
  }
  // 创建/上传视频
  createVideo(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param);
    objArr.map((item:any) =>
    {
      if(item !== 'tags')
      {
        formData.append(item, param[item]);
      }
      else
      {
        for(let i = 0; i < param.tags.length; i++)
        {
          formData.append(`tags[]`, param.tags[i]);
        }
      }
    });

    return this.http.post(this.domain + `/videos`, formData, formDataHttpOption())
  }
  // 更新视频
  updateVideo(param:any)
  {
    let formData : any = new FormData();
    formData.append('_method', 'patch');

    let objArr = Object.keys(param.data);
    objArr.map((item:any) =>
    {
      if(item !== 'tags')
      {
        formData.append(item, param.data[item]);
      }
      else
      {
        for(let i = 0; i < param.data.tags.length; i++)
        {
          formData.append(`tags[]`, param.data.tags[i]);
        }
      }
    });
    
    return this.http.post(this.domain + `/videos/${param.id}`, formData, formDataHttpOption())
  }
  // 删除视频
  deleteVideo(id:any)
  {
    return this.http.delete(this.domain + `/videos/${id}`, httpOptions())
  }





  // 大师教学接口
  // ------------------------------------------
  // 获取大师教学列表
  getEducationList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/master-tutorials` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取视频信息
  getEducationDetail(param:any)
  {
    return this.http.get(this.domain + `/master-tutorials/${param.id}`, httpOptions())
  }
  // 创建/上传视频
  createEducaion(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param);
    objArr.map((item:any) =>
    {
      if(item !== 'tags')
      {
        formData.append(item, param[item]);
      }
      else
      {
        for(let i = 0; i < param.tags.length; i++)
        {
          formData.append(`tags[]`, param.tags[i]);
        }
      }
    });

    return this.http.post(this.domain + `/master-tutorials`, formData, formDataHttpOption())
  }
  // 更新视频
  updateEducation(param:any)
  {
    let formData : any = new FormData();
    formData.append('_method', 'patch');

    let objArr = Object.keys(param.data);
    objArr.map((item:any) =>
    {
      if(item !== 'tags')
      {
        formData.append(item, param.data[item]);
      }
      else
      {
        for(let i = 0; i < param.data.tags.length; i++)
        {
          formData.append(`tags[]`, param.data.tags[i]);
        }
      }
    });
    return this.http.post(this.domain + `/master-tutorials/${param.id}`, formData, formDataHttpOption())
  }
  // 删除视频
  deleteEducation(id:any)
  {
    return this.http.delete(this.domain + `/master-tutorials/${id}`, httpOptions())
  }





  // 横幅接口
  // ------------------------------------------
  // 获取横幅列表
  getBannerList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/banners` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取横幅信息
  getBannerDetail(param:any)
  {
    return this.http.get(this.domain + `/banners/${param.id}`, httpOptions())
  }
  // 创建横幅
  createBanner(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param);
    objArr.map((item:any) =>
    {
      formData.append(item, param[item]);
    });

    return this.http.post(this.domain + `/banners/store`, formData, formDataHttpOption())
  }
  // 更新横幅
  updateBanner(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param.data);
    objArr.map((item:any) =>
    {
      formData.append(item, param.data[item]);
    });

    return this.http.post(this.domain + `/banners/${param.id}/update`, formData, formDataHttpOption())
  }
  // 删除横幅
  deleteBanner(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param);
    objArr.map((item:any) =>
    {
      formData.append(item, param[item]);
    });

    return this.http.post(this.domain + `/banners/${param.id}/delete`, formData, formDataHttpOption())
  }






  // 热门活动接口
  // ------------------------------------------
  // 获取热门活动列表
  getActivitiesList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/hot-events` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 获取热门活动信息
  getActivityDetail(param:any)
  {
    return this.http.get(this.domain + `/hot-events/${param.id}`, httpOptions())
  }
  // 创建热门活动
  createActivity(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param);
    objArr.map((item:any) =>
    {
      formData.append(item, param[item]);
    });

    return this.http.post(this.domain + `/hot-events/store`, formData, formDataHttpOption())
  }
  // 更新热门活动
  updateActivity(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param.data);
    objArr.map((item:any) =>
    {
      formData.append(item, param.data[item]);
    });

    return this.http.post(this.domain + `/hot-events/${param.id}/update`, formData, formDataHttpOption())
  }
  // 删除热门活动
  deleteActivity(param:any)
  {
    let formData : any = new FormData();
    let objArr = Object.keys(param);
    objArr.map((item:any) =>
    {
      formData.append(item, param[item]);
    });

    return this.http.post(this.domain + `/hot-events/${param.id}/delete`, formData, formDataHttpOption())
  }





  // 白名单接口
  // ------------------------------------------
  // 获取白名单列表
  getWhiteList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/whitelisted-countries` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 添加白名单
  addWhiteList(param:any)
  {
    return this.http.post(this.domain + `/whitelisted-countries/store`, qs.stringify(param), httpOptions())
  }






  // 黑名单接口
  // ------------------------------------------
  // 获取黑名单列表
  getBlackList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/blacklisted-countries` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 添加白名单
  addBlackList(param:any)
  {
    return this.http.post(this.domain + `/blacklisted-countries/store`, qs.stringify(param), httpOptions())
  }
  // 获取黑名单列表
  getBlackListCategory(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/blacklisted-countries/get-blacklist-categories` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 删除黑名单
  removeBlacklist(param:any)
  {
    return this.http.post(this.domain + `/blacklisted-countries/${param.id}/delete`, qs.stringify(param), httpOptions())
  }




  






  // 标签接口
  // ------------------------------------------
  // 获取标签列表
  getTagList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/tags` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 创建标签
  createTag(param:any)
  {
    return this.http.post(this.domain + `/tags/store`, qs.stringify(param), httpOptions())
  }
  // 删除标签
  removeTag(param:any)
  {
    return this.http.post(this.domain + `/tags/${param.tag}/delete`, qs.stringify(param), httpOptions())
  }




  // 敏感字眼接口
  // ------------------------------------------
  // 获取字眼列表
  getWordsList(param:any)
  {
    let queryString = Object.keys(param).map((key:any) => {
        return key + '=' + param[key]
    }).join('&');
    return this.http.get(this.domain + `/sensitive-words` + (queryString ? `?${queryString}` : ''), httpOptions())
  }
  // 创建字眼
  createWords(param:any)
  {
    return this.http.post(this.domain + `/sensitive-words`, qs.stringify(param), httpOptions())
  }
  // 删除字眼
  removeWords(param:any)
  {
    return this.http.delete(this.domain + `/sensitive-words/${param.id}`, httpOptions())
  }

}

