import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'blank-layout',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
})
export class BlankLayout implements OnInit {
  constructor() { };

  ngDoCheck(): void {
    
  }

  ngOnInit(): void {
    
  }
}
