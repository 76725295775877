<div class="profilecover">
    <div class="head"></div>
    <div class="content">
        <div class="profile">
            <div class="id">AA0001</div>
            <div class="text">DL</div>
            <div class="date">Register since 1-8-2022</div>
        </div>
        <div class="form">
            <input-box [data]="formData.id"></input-box>
            <input-box [data]="formData.position"></input-box>

            <input-box [data]="formData.firstname"></input-box>
            <input-box [data]="formData.lastname"></input-box>

            <input-box [data]="formData.displayname"></input-box>

            <div class="clear-row"></div>

            <input-box [data]="formData.phone"></input-box>
            <input-box [data]="formData.email"></input-box>

            <input-box [data]="formData.dob"></input-box>
            <input-box [data]="formData.gender"></input-box>

            <input-box [data]="formData.address1"></input-box>
            <input-box [data]="formData.address2"></input-box>
            
            <input-box [data]="formData.postcode"></input-box>
            <input-box [data]="formData.statecountry"></input-box>

            <div class="clear-row"></div>

            <input-box [data]="formData.bio"></input-box>

            <div class="clear-row"></div>

            <button-box [data]="formData.update"></button-box>
        </div>

        <div class="clear-row"></div>
    </div>
</div>