<div class="dialogbox" *ngIf="dataObj">
  <div class="overlay"></div>
  <div class="dialogcover">
    <div class="text">
      <div *ngFor="let data of dataObj.data; index as index;">{{ data }}</div>
    </div>
    <div class="button" *ngIf="dataObj.action || dataObj.cancel">
      <button-box *ngIf="dataObj.cancel" style="display:block; width:100%" [data]="button.cancel.data" (click)="cancel()"></button-box>
      <button-box *ngIf="dataObj.action" style="display:block; width:100%" [data]="button.action.data" (click)="action()"></button-box>
    </div>
  </div>
</div>