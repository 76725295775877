import { Injectable } from '@angular/core';
import { ApiService } from '../service/api.service';

@Injectable()
export class GlobalStore {
  constructor(
    private ApiService : ApiService
  ) { }

  'notification': any     = null;
  'dialog'      : any     = null;
  'loader'      : boolean = false;

  'loginStatus' : boolean = false;
  'userInfo'    : any = null;


  // user function
  getUserInfo(callback?: (res: any) => void, errorback?: (res: any) => void)
  {
    this.loader = true;
    this.ApiService.getUserInfo().subscribe(
      (res: any) => {
        if(res.data)
        {
          callback && callback(res);
        }
      },
      (error:any) =>
      {
        this.userInfo = null;
        this.loginStatus = false;
        localStorage.removeItem('token');

        errorback && errorback(error);
      }
    );
  }
}